import { Currency, currencyEquals, WETH, XDC } from "into-the-fathom-swap-sdk";
import { useMemo, useState } from "react";
import { tryParseAmount } from "apps/dex/state/swap/hooks";
import { useTransactionAdder } from "apps/dex/state/transactions/hooks";
import { useCurrencyBalance } from "apps/dex/state/wallet/hooks";
import { useActiveWeb3React } from "apps/dex/hooks";
import { useWETHContract } from "apps/dex/hooks/useContract";

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE };
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined
): {
  wrapType: WrapType;
  execute?: undefined | (() => Promise<void>);
  inputError?: string;
  wrappingLoading?: boolean;
} {
  const [wrappingLoading, setWrappingLoading] = useState<boolean>(false);
  const { chainId, account } = useActiveWeb3React();
  const wethContract = useWETHContract();
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency);
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(
    () => tryParseAmount(typedValue, inputCurrency),
    [inputCurrency, typedValue, chainId]
  );
  const addTransaction = useTransactionAdder();

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency)
      return NOT_APPLICABLE;

    const sufficientBalance =
      inputAmount && balance && !balance.lessThan(inputAmount);

    if (
      inputCurrency === XDC &&
      currencyEquals(WETH[chainId], outputCurrency)
    ) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                setWrappingLoading(true);
                try {
                  const txReceipt = await wethContract.deposit({
                    value: `0x${inputAmount.raw.toString(16)}`,
                  });
                  addTransaction(txReceipt, {
                    summary: `Wrap ${inputAmount.toSignificant(6)} XDC to WXDC`,
                  });
                  txReceipt.wait().then(() => {
                    setWrappingLoading(false);
                  });
                } catch (error) {
                  console.error("Could not deposit", error);
                  setWrappingLoading(false);
                }
              }
            : undefined,
        inputError: !inputAmount
          ? "Enter an amount"
          : sufficientBalance
          ? undefined
          : "Insufficient XDC balance",
        wrappingLoading,
      };
    } else if (
      currencyEquals(WETH[chainId], inputCurrency) &&
      outputCurrency === XDC
    ) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                setWrappingLoading(true);
                try {
                  const txReceipt = await wethContract.withdraw(
                    `0x${inputAmount.raw.toString(16)}`
                  );
                  addTransaction(txReceipt, {
                    summary: `Unwrap ${inputAmount.toSignificant(
                      6
                    )} WXDC to XDC`,
                  });
                  txReceipt.wait().then(() => {
                    setWrappingLoading(false);
                  });
                } catch (error) {
                  console.error("Could not withdraw", error);
                  setWrappingLoading(false);
                }
              }
            : undefined,
        inputError: !inputAmount
          ? "Enter an amount"
          : sufficientBalance
          ? undefined
          : "Insufficient WXDC balance",
        wrappingLoading,
      };
    } else {
      return NOT_APPLICABLE;
    }
  }, [
    wethContract,
    chainId,
    inputCurrency,
    outputCurrency,
    inputAmount,
    balance,
    addTransaction,
  ]);
}
